import React from 'react'
import { Seo } from '../components'
import useWindowSize from '../hooks/useWindowSize'
import { usePrismic } from '../context/PrismicContext'
import { Container, Wrapper } from '../styles/common'
import { Container as TextContainer } from 'semantic-ui-react'

const PrivacyPolicyPage = () => {
  const {
    prismicData: { prismicPrivacyPolicyPage },
  } = usePrismic()

  const { width } = useWindowSize()

  return (
    <>
      <Seo title="privacy-policy" />
      <Wrapper>
        <Container style={{ flex: 1 }}>
          <TextContainer style={{ padding: '1rem' }} text>
            {prismicPrivacyPolicyPage.privacy_content.map(content => (
              <p>{content.text}</p>
            ))}
          </TextContainer>
        </Container>
      </Wrapper>
    </>
  )
}

export default PrivacyPolicyPage
